<template>
  <sm-page-loader
    v-if="isLoadingPage"
    :class="{ 'page-loader-relative': isLoadingPageRelative }"
  />

  <sm-permission-error-message v-else-if="!hasViewListPermission" />

  <div v-else class="editable-list">
    <!-- NAV -->
    <sm-breadcrumbs class="editable-list__breadcrumbs" :items="breadcrumbs" />
    <!-- / NAV -->

    <!--  -->
    <slot name="filter-top-panet"></slot>
    <!--  -->

    <!-- FILTER -->
    <sm-filter
      v-if="showFilter"
      v-model="filterForm"
      :fields="currentFilterField"
      :quickFilterFieldsNumber="quickFilterFieldsNumber"
      :filterBadges="filterBadges"
      :isLoadingApplyButton="list.isLoading"
      @removeBadge="onRemoveBadge"
      @search="onSearch"
      @reset="onReset"
      @fetchItems="onFetchItems"
      @updateFilter="onUpdateFilter"
      @clearChildList="clearChildList"
    />
    <!-- / FILTER -->

    <!-- DEPENDENT LIST -->
    <div class="editable-list__long-process" v-if="longProcessLinks">
      <span class="editable-list__long-process-text">Обработки:</span>
      <ul class="editable-list__long-process-list">
        <li
          v-for="link in longProcessLinks"
          :key="link.text"
          class="editable-list__long-process-item"
        >
          <a
            class="editable-list__long-process-link"
            @click="onClickLongProcessLink(link.route)"
            >{{ link.text }}</a
          >
        </li>
      </ul>
    </div>
    <!-- / DEPENDENT LIST -->

    <!--  -->
    <slot
      name="list-top-panet"
      :checkedItemIds="checkedItemIds"
      :filters="filterForRequest"
      :table="table"
      :columns="processedTableHeaders"
    >
    </slot>
    <!-- / -->

    <!-- DATATABLE -->
    <slot name="custom-table"></slot>

    <sm-datatable
      v-if="!isCustomTable"
      class="editable-list__datatable"
      :caption="tableCaption"
      :headers="processedTableHeaders"
      :items="processedList"
      :multiselect="multiselect"
      :checkedItems="checkedItemsLocal"
      sort
      select-items-per-page
      pagination
      :total-items="currentList.data ? currentList.data.count : 0"
      :items-per-page="table.count"
      :activePage="table.skip / table.count + 1"
      :activeOrder="table.order"
      :sortedType="table.orderDesc ? 'desc' : 'asc'"
      @sort-by="onSortBy"
      @check-items="onCheckItems"
      @change-items-per-page="onItemsPerPage"
      @change-page="onChangePage"
    >
      <!-- DATATABLE TOP PANEL -->
      <template #top-panel>
        <sm-available-columns
          v-model="availableHeadersForm"
          :fields="tableHeaders"
        />
        <button
          v-if="
            showCreateButton &&
            !checkedItemIds.length &&
            hasEditListItemPermission
          "
          class="editable-list__top-panel-button editable-list__top-panel-button--create"
          @click="onCreate"
        >
          <sm-icon name="plus" outline :stroke-width="3" />
          Добавить
        </button>
        <button
          v-if="showMultideleteButton && checkedItemIds.length"
          class="editable-list__top-panel-button editable-list__top-panel-button--delete"
          @click="openMultideleteModal"
        >
          Удалить
        </button>

        <!-- DATATABLE-TOP-PANLE-SLOT -->
        <slot name="datatable-top-panel"></slot>
        <!-- /DATATABLE-TOP-PANLE-SLOT -->
      </template>
      <!-- / DATATABLE TOP PANEL -->

      <!-- DATATABLE COLGROUP -->
      <template #colgroup>
        <col
          width="auto"
          :span="
            showEditButton || showDeleteButton || showViewButton
              ? processedTableHeaders.length - 1
              : processedTableHeaders.length
          "
        />
        <col
          v-if="showEditButton || showDeleteButton || showViewButton"
          width="80px"
        />
      </template>
      <!-- / DATATABLE COLGROUP -->

      <!-- DATATABLE  BODY -->
      <template v-for="(_, slot) in $scopedSlots" v-slot:[slot]="row">
        <slot :name="slot" v-bind="row"></slot>
      </template>

      <template v-slot:actions="{ row }">
        <div class="editable-list__datatable-buttons">
          <slot name="customAction" :row="row" />
          <template v-if="showEditButton || showDeleteButton || showViewButton">
            <sm-tooltip
              v-if="showEditButton && hasEditListItemPermission"
              position="bottom"
              text="Изменить"
              class="editable-list__datatable-tooltip"
            >
              <button
                class="editable-list__datatable-button editable-list__datatable-button--edit"
                @click="onEdit(row.id)"
              >
                <sm-icon name="pencil-alt" />
              </button>
            </sm-tooltip>

            <sm-tooltip
              v-if="
                showViewButton || (!hasEditListItemPermission && showEditButton)
              "
              position="bottom"
              text="Открыть"
              class="editable-list__datatable-tooltip"
            >
              <button
                class="editable-list__datatable-button editable-list__datatable-button--view"
                @click="onEdit(row.id)"
              >
                <sm-icon name="eye" />
              </button>
            </sm-tooltip>

            <sm-tooltip
              v-if="showDeleteButton && hasEditListItemPermission"
              position="bottom"
              text="Удалить"
              class="editable-list__datatable-tooltip"
            >
              <button
                class="editable-list__datatable-button editable-list__datatable-button--delete"
                @click="openDeleteModal(row.id)"
              >
                <sm-icon name="trash" />
              </button>
            </sm-tooltip>
          </template>
        </div>
      </template>
      <!-- / DATATABLE  BODY -->
    </sm-datatable>
    <!-- / DATATABLE -->

    <!-- BOTTOM PANEL -->
    <slot name="list-bottom-panel"></slot>
    <!-- / -->
  </div>
</template>

<script>
// vuex
import { createNamespacedHelpers, mapActions } from 'vuex';
const { mapState } = createNamespacedHelpers('editableList');

// components
import SmPageLoader from '@/components/common/SmPageLoader.vue';
import smPermissionErrorMessage from '@/components/common/smPermissionErrorMessage.vue';
import SmBreadcrumbs from '@/components/common/breadcrumbs/SmBreadcrumbs.vue';
import SmFilter from '@/components/common/editableList/SmEditableListFilter.vue';
import SmAvailableColumns from '@/components/common/editableList/SmAvailableColumns.vue';
import SmDatatable from '@/components/common/SmDatatable.vue';
import SmIcon from '@/components/common/SmIcon.vue';
import SmTooltip from '@/components/common/SmTooltip.vue';

import filterTypes from '@/constants/filterTypes.js';

import {
  getItem as getItemFromStorage,
  setItem as setItemToStorage,
} from '@/utils/persistenceStorage';

export default {
  name: 'SmEditableList',

  components: {
    SmPageLoader,
    smPermissionErrorMessage,
    SmBreadcrumbs,
    SmFilter,
    SmAvailableColumns,
    SmDatatable,
    SmIcon,
    SmTooltip,
  },

  props: {
    isGeneralApi: {
      type: Boolean,
      default: false,
    },

    breadcrumbs: {
      type: Array,
    },

    tableCaption: {
      type: String,
    },

    tableHeaders: {
      type: Array,
      require: true,
    },

    controllerName: {
      type: String,
      require: true,
    },

    parentName: {
      type: String,
    },

    showEditButton: {
      type: Boolean,
      default: false,
    },

    showViewButton: {
      type: Boolean,
      default: false,
    },

    showDeleteButton: {
      type: Boolean,
      default: false,
    },

    showCreateButton: {
      type: Boolean,
      default: false,
    },

    multiselect: {
      type: Boolean,
      default: false,
    },

    showMultideleteButton: {
      type: Boolean,
      default: false,
    },

    checkedItems: {
      type: Array,
      default: () => [],
    },

    showFilter: {
      type: Boolean,
      default: true,
    },

    filterFields: {
      type: Array,
      default: () => [],
    },

    quickFilterFieldsNumber: {
      type: Number,
      default: 2,
    },

    tableDefaultValue: {
      type: Object,
      default: () => {
        return {
          order: 'id',
          count: 10,
          skip: 0,
          orderDesc: true,
        };
      },
    },

    isCustomTable: {
      type: Boolean,
      default: false,
    },

    longProcessLinks: {
      type: Array,
    },

    isLoadingPageRelative: {
      type: Boolean,
      default: false,
    },

    isLoading: {
      type: Boolean,
      default: false,
    },

    showNextpageQuestion: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isInitialLoading: false,
      table: null,
      checkedItemIds: [],
      idsToDelete: null,
      initialFilterForm: [],
      filterForm: [],
      filterForQuery: {},
      filterForRequest: [],
      filterBadges: [],
      availableHeadersForm: {},
      processedFilterForm: {},
      badges: [],
      checkedItemsLocal: [],
    };
  },

  computed: {
    ...mapState(['listParameters', 'list', 'childList']),

    isLoadingPage() {
      return this.isInitialLoading || this.isLoading;
    },

    hasViewListPermission() {
      if (!this.validatePermission) {
        return true;
      }
      return this.$ability.checkPermissions(
        'Table',
        this.controllerName,
        'View'
      );
    },

    hasEditListItemPermission() {
      if (!this.validatePermission) {
        return true;
      }
      return this.$ability.checkPermissions(
        'Table',
        this.controllerName,
        'Edit'
      );
    },

    // [START] Computed for filter
    fieldsAlias() {
      if (this.filterFields.length) {
        return this.filterFields
          .map((item) => item.alias)
          .filter((item) => item !== 'actions');
      }
      return this.tableHeaders
        .map((item) => item.alias)
        .filter((item) => item !== 'actions');
    },

    processedListParameterColumns() {
      if (this.listParameters.data?.columns.length) {
        const processedListParameters = this.listParameters.data.columns.filter(
          (item) =>
            this.fieldsAlias.includes(item.name) && item.filterType !== 'None'
        );

        if (this.filterFields.length) {
          return this.fieldsAlias.map((item) => {
            return {
              ...this.currentFieldsForAlias.find((elem) => item === elem.alias),
              ...processedListParameters.find((elem) => item === elem.name),
            };
          });
        }

        if (this.listParameters.data?.columns.length) {
          return this.fieldsAlias
            .map((item) =>
              processedListParameters.find((elem) => item === elem.name)
            )
            .filter((item) => item);
        }
      }

      return [];
    },

    currentFieldsForAlias() {
      if (this.filterFields.length) {
        return this.filterFields;
      }
      return this.tableHeaders.filter((item) => item.alias !== 'actions');
    },

    currentFilterField() {
      if (this.processedListParameterColumns.length) {
        const processedFilterColumns = this.processedListParameterColumns.map(
          (item) => {
            let values;

            if (item.filterType === 'FromCatalog') {
              if (item.catalogParent !== null) {
                values =
                  this.computedFilterForm[item.catalogParent] &&
                  this.computedFilterForm[item.catalogParent].length <= 10 &&
                  this.$store.state.filter[item.catalogName]?.data?.items
                    ? this.$store.state.filter[item.catalogName].data.items
                    : null;
              } else {
                values =
                  this.$store.state.filter[item.catalogName]?.data?.items;
              }
            } else {
              values = null;
            }

            if (item.filterType === 'FromCatalog' && item.catalogParent) {
              let uniqueValues;

              if (values) {
                uniqueValues = values.reduce((acc, item) => {
                  const currentItem = acc.find((el) => el.name === item.name);
                  if (currentItem) {
                    currentItem.ids.push(item.id);
                  } else {
                    item.ids = [item.id];
                    acc.push(item);
                  }
                  return acc;
                }, []);
              } else {
                uniqueValues = null;
              }

              const obj = {
                ...item,
                label: this.currentFieldsForAlias.find(
                  (element) => element.alias === item.name
                )?.text,
                values: uniqueValues,
              };

              return obj;
            } else {
              const obj = {
                ...item,
                name: item.name,
                label: this.currentFieldsForAlias.find(
                  (element) => element.alias === item.name
                )?.text,
                values: values ? values : item.values,
              };

              return obj;
            }
          }
        );

        return processedFilterColumns;
      }
      return [];
    },

    filterTypesValues() {
      return [...filterTypes.String, ...filterTypes.Int];
    },

    computedFilterForm() {
      return Object.assign({}, this.filterForm);
    },

    isCurrentFilterFieldValuesLoaded() {
      const fromCatalogItems = this.currentFilterField.filter(
        (item) =>
          item.filterType === 'FromCatalog' && this.filterForm[item.name]
      );

      return fromCatalogItems.every((item) => item.values);
    },
    // [END] Computed for filter

    // [START] Computed for list
    currentList() {
      if (this.currentParentName && this.parentId) {
        return this.childList;
      }
      return this.list;
    },

    processedList() {
      if (
        this.currentList.data &&
        this.currentList.data.items &&
        this.currentList.data.items.length
      ) {
        return this.currentList.data.items.map((item) => {
          const pocessedItem = {};
          for (const key in item) {
            if (typeof item[key] === 'boolean') {
              pocessedItem[key] = item[key] ? 'Да' : 'Heт';
            } else {
              pocessedItem[key] = item[key];
            }
          }
          return pocessedItem;
        });
      }

      return [];
    },

    currentParentName() {
      return this.parentName || this.$route.params.parent;
    },

    processedTableHeaders() {
      if (this.availableHeadersForm) {
        this.setInitialAvailableHeadersForm();
        return this.tableHeaders.filter((header) => {
          return this.availableHeadersForm[header.alias].checked;
        });
      }
      return this.tableHeaders;
    },

    // parentName() {
    //   return this.$route.params.parent;
    // },

    parentId() {
      return +this.$route.params.id;
    },
    // [END] Computed for list
  },

  watch: {
    //  [START] Посмотреть почему не работает глубокое отслеживание
    table: {
      handler() {
        this.setfilterBadges();
        this.fetchList();
      },
      deep: true,
    },

    'table.order': {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.setFilterToQuery();
        }
      },
    },

    'table.count': {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.setFilterToQuery();
        }
      },
    },

    'table.skip': {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.setFilterToQuery();
        }
      },
    },

    'table.orderDesc': {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.setFilterToQuery();
        }
      },
    },
    //  [END] Посмотреть почему не работает глубокое отслеживание

    availableHeadersForm: {
      handler(newValue) {
        const checked = Object.entries(newValue).filter((item) => {
          return item[1].checked && item[0] !== 'actions';
        });
        const disabled = Object.entries(newValue).filter((item) => {
          return item[1].disabled;
        });
        if (checked.length == 1) {
          checked[0][1].disabled = true;
        }

        if (disabled.length > 0 && checked.length > 1) {
          disabled.forEach((item) => {
            item[1].disabled = false;
          });
        }
        let tables = getItemFromStorage('availableTablesColumns');
        if (!tables) {
          tables = {};
        }
        tables[this.controllerName] = newValue;
        setItemToStorage('availableTablesColumns', tables);
      },
      deep: true,
    },

    processedListParameterColumns: {
      handler(newValue, oldValue) {
        const isEqual = this.lodash.isEqual(newValue, oldValue);
        if (isEqual) return;

        this.fetchFilterCatalogs(newValue);
      },
    },

    processedFilterForm: {
      handler(newValue, oldValue) {
        const isEqual = this.lodash.isEqual(newValue, oldValue);
        if (isEqual) return;
        // this.setFilterToQuery();

        const childFilterList = this.currentFilterField.filter(
          (listParameter) => {
            if (!listParameter.catalogParent) return;
            return newValue[listParameter.catalogParent];
          }
        );

        if (!childFilterList.length) return;

        childFilterList.forEach((field) => {
          // Проверка, если родительский фильтр является массивом, то получение зависимых каталогов недоступно
          // if (Array.isArray(this.filterForm[field.catalogParent])) return;
          if (Array.isArray(newValue[field.catalogParent])) {
            const parentCatalog = newValue[field.catalogParent];
            // Проверка, отправлять запрос на получение дочерних каталогов, если в родительском каталоге выбрано меньше 10 значений, а также если у дочернего каталога есть свой зависимый каталог
            if (newValue[field.catalogParent] || parentCatalog.length <= 10) {
              // Проверка, если параметр зависимого списка уже выбран, то не посылать запрос
              if (
                newValue[field.name].length &&
                typeof oldValue[field.name] !== 'undefined'
              )
                return;

              this.getMultipleFilterCatalogs({
                catalogName: field.catalogName,
                parentItems: newValue[field.catalogParent],
              });
            }
          } else if (
            typeof newValue[field.catalogParent] === 'object' &&
            'ids' in newValue[field.catalogParent]
          ) {
            // Запрос для зависимых списков, у которых есть свои зависимые списки
            this.getMultipleFilterCatalogs({
              catalogName: field.catalogName,
              parentItems: newValue[field.catalogParent].ids,
            });
          } else {
            // Проверка, если зависимый список уже выбран, то не посылать запрос
            // if (newValue[field.name]) return;

            this.getFilterCatalogs({
              catalogName: field.catalogName,
              params: {
                parentId: newValue[field.catalogParent],
              },
            });
          }

          if (!newValue[field.catalogParent]) {
            this.currentFilterField[field.name].values = null;
          }
        });

        const catalogDependenciesList =
          this.processedListParameterColumns.filter((listParameter) => {
            if (
              listParameter.catalogDependencies &&
              listParameter.catalogDependencies.length
            )
              return listParameter;
          });

        if (catalogDependenciesList.length) {
          catalogDependenciesList.forEach((field) => {
            const dependencyFilters = field.catalogDependencies.map((item) => {
              const values = Array.isArray(newValue[item.columnName])
                ? this.filterForm[item.columnName].map((item) => Number(item))
                : [Number(newValue[item.columnName])];

              return {
                name: item.dependencyName,
                values: newValue[item.columnName] ? values : [],
              };
            });

            // Если значения из текущего каталога выбраны, не посылать повторный запрос
            if (newValue[field.catalogName]) return;

            this.getFilterCatalogsItems({
              name: field.catalogName,
              filter: null,
              limit: 10,
              parentId: field.catalogParent
                ? this.filterForm[field.catalogParent]
                : null,
              dependencyFilters,
            });
          });
        }
      },
      deep: true,
    },

    currentFilterField: {
      handler(newValue, oldValue) {
        if (newValue && newValue !== oldValue) {
          this.processedFilterForm = Object.fromEntries(
            Object.entries(this.filterForm).map(([k, v]) => {
              const currentField = this.currentFilterField.find(
                (field) => field.name === k
              );

              if (
                currentField &&
                currentField.catalogParent &&
                currentField.values &&
                this.filterForm[currentField.name]
              ) {
                const currentValue = Array.isArray(v)
                  ? currentField.values.filter((value) => v.includes(value.id))
                  : currentField.values.filter((value) => v === value.id);

                if (currentValue && Array.isArray(currentValue)) {
                  const ids = currentValue.map((value) => value.ids);
                  return [k, ids.flat(1)];
                }
                return [
                  k,
                  {
                    value: currentValue.id,
                    ids: currentValue.ids,
                  },
                ];
              }
              return [k, v];
            })
          );
        }
      },
      deep: true,
      immediate: true,
    },

    computedFilterForm: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal && oldVal && !this.lodash.isEmpty(oldVal)) {
          Object.keys(newVal).forEach((key) => {
            const currentField = this.currentFilterField.find(
              (field) =>
                field.name === key && field.filterType === 'FromCatalog'
            );
            if (currentField && currentField.catalogParent) {
              // Если у родительского каталога новое выбранное значение, то сбросить у дочернего
              if (
                oldVal[currentField.catalogParent] !==
                  this.filterForm[currentField.catalogParent] &&
                this.filterForm[currentField.name]
              ) {
                this.filterForm[currentField.name] = Array.isArray(
                  this.filterForm[currentField.name]
                )
                  ? []
                  : '';
              }
            }
          });
        }
      },
      immediate: true,
      deep: true,
    },

    checkedItems: {
      handler(newValue) {
        this.checkedItemsLocal = newValue;
      },
    },

    isCurrentFilterFieldValuesLoaded: {
      handler(newVal) {
        if (newVal) {
          this.setfilterBadges();
          this.setInitialFilterBadges();
          this.compressionFilterForRequest();
        }
      },
    },

    filterForm: {
      handler(newValue) {
        if (newValue) {
          this.processedFilterForm = Object.fromEntries(
            Object.entries(this.filterForm).map(([k, v]) => {
              const currentField = this.currentFilterField.find(
                (field) => field.name === k
              );

              if (
                currentField &&
                currentField.catalogParent &&
                currentField.values &&
                this.filterForm[currentField.name]
              ) {
                const currentValue = Array.isArray(v)
                  ? currentField.values.filter((value) => v.includes(value.id))
                  : currentField.values.filter((value) => v === value.id);

                if (currentValue && Array.isArray(currentValue)) {
                  const ids = currentValue.map((value) => value.ids);
                  return [k, ids.flat(1)];
                }
                return [
                  k,
                  {
                    value: currentValue.id,
                    ids: currentValue.ids,
                  },
                ];
              }
              return [k, v];
            })
          );
        }
      },
      deep: true,
      immediate: true,
    },
  },

  created() {
    if (!this.hasViewListPermission) return;
    this.isInitialLoading = true;

    this.getListParameters({
      name: this.controllerName,
    })
      .then(() => {
        this.setInitialFilterForm();
        this.filterForm = this.lodash.cloneDeep(this.initialFilterForm);

        this.setInitialAvailableHeadersForm();

        if (this.$route.query?.filter) {
          let filterFromQuery = JSON.parse(this.$route.query.filter);

          Object.keys(filterFromQuery).forEach((key) => {
            if (
              filterFromQuery[key] &&
              filterFromQuery[key].operation &&
              filterFromQuery[key].operation === 'Equal' &&
              filterFromQuery[key].values
            ) {
              return (filterFromQuery[key] = filterFromQuery[key].values);
            }
            return filterFromQuery[key];
          });

          this.filterForm = {
            ...this.filterForm,
            ...filterFromQuery,
          };

          this.processedFilterForm = Object.fromEntries(
            Object.entries(this.filterForm).map(([k, v]) => {
              const currentField = this.currentFilterField.find(
                (field) => field.name === k
              );

              if (
                currentField &&
                currentField.catalogParent &&
                currentField.values &&
                this.filterForm[currentField.name]
              ) {
                const currentValue = Array.isArray(v)
                  ? currentField.values.filter((value) => v.includes(value.id))
                  : currentField.values.filter((value) => v === value.id);

                if (currentValue && Array.isArray(currentValue)) {
                  const ids = currentValue.map((value) => value.ids);
                  return [k, ids.flat(1)];
                }
                return [
                  k,
                  {
                    value: currentValue.id,
                    ids: currentValue.ids,
                  },
                ];
              }
              return [k, v];
            })
          );

          this.compressionFilterForRequest();
        }

        if (this.$route.query?.table) {
          const sortFromQuery = JSON.parse(this.$route.query.table);
          this.table = {
            ...this.table,
            ...sortFromQuery,
          };
        } else {
          this.table = { ...this.tableDefaultValue };
        }

        this.checkedItemsLocal = this.checkedItems;
      })
      .finally(() => {
        this.isInitialLoading = false;
      });
  },

  methods: {
    ...mapActions({
      getList: 'editableList/getList',
      getListParameters: 'editableList/getListParameters',
      getChildList: 'editableList/getChildList',
      removeItem: 'editableList/removeItem',
      getFilterCatalogs: 'filter/getFilterCatalogs',
      getFilterCatalogsItems: 'filter/getFilterCatalogsItems',
      getMultipleFilterCatalogs: 'filter/getMultipleFilterCatalogs',
      getFilterItemsById: 'filter/getFilterItemsById',
    }),

    // После проверки работоспособности фильтров, провести рефакторинг
    // [START] Methods for filter
    setInitialFilterForm() {
      this.initialFilterForm = this.processedListParameterColumns.reduce(
        (previousItem, currentItem) => {
          if (
            ['Bool', 'FromList', 'FromCatalog'].includes(currentItem.filterType)
          ) {
            return {
              ...previousItem,
              [currentItem.name]: '',
            };
          }
          return {
            ...previousItem,
            [currentItem.name]: {
              operation: '',
              value: '',
              valueFrom: '',
              valueTo: '',
            },
          };
        },
        {}
      );
    },

    compressionFilterForQuery() {
      const filterFormForQuery = {};

      for (const key in this.processedFilterForm) {
        if (
          typeof this.processedFilterForm[key] === 'object' &&
          this.processedFilterForm[key].operation
        ) {
          if (
            this.processedFilterForm[key].operation === 'Between' &&
            this.processedFilterForm[key].valueFrom !== '' &&
            this.processedFilterForm[key].valueTo !== ''
          ) {
            filterFormForQuery[key] = { ...this.processedFilterForm[key] };
            delete this.processedFilterForm[key].value;
          } else if (
            this.processedFilterForm[key].operation === 'Equal' ||
            this.processedFilterForm[key].operation === 'NotEqual' ||
            this.processedFilterForm[key].value
          ) {
            filterFormForQuery[key] = { ...this.filterForm[key] };
            delete this.processedFilterForm[key].valueFrom;
            delete this.processedFilterForm[key].valueTo;
          }
          // else if (this.filterForm[key].value) {
          //   processedFilterForm[key] = { ...this.filterForm[key] };
          //   delete this.filterForm[key].valueFrom;
          //   delete this.filterForm[key].valueTo;
          // }
        } else if (
          typeof this.filterForm[key] === 'boolean' ||
          (typeof this.filterForm[key] === 'string' && this.filterForm[key])
        ) {
          filterFormForQuery[key] = this.filterForm[key];
        } else if (Array.isArray(this.filterForm[key])) {
          const values = [];
          this.filterForm[key].forEach((item) => {
            values.push(item);
          });

          filterFormForQuery[key] = {
            operation: 'Equal',
            values,
          };
        }
      }

      this.filterForQuery = filterFormForQuery;
    },

    compressionFilterForRequest() {
      const processedFilter = [];

      for (const key in this.processedFilterForm) {
        if (
          typeof this.processedFilterForm[key] === 'object' &&
          this.processedFilterForm[key].operation
        ) {
          if (
            this.processedFilterForm[key].operation === 'Between' &&
            this.processedFilterForm[key].valueFrom !== '' &&
            this.processedFilterForm[key].valueTo !== ''
          ) {
            const field = { column: key, ...this.processedFilterForm[key] };

            processedFilter.push(field);
          } else if (
            this.processedFilterForm[key].operation === 'Equal' ||
            this.processedFilterForm[key].operation === 'NotEqual' ||
            this.processedFilterForm[key].value
          ) {
            const field = { column: key, ...this.filterForm[key] };

            processedFilter.push(field);
          } else {
            this.processedFilterForm[key].operation = '';
          }
        } else if (
          typeof this.processedFilterForm[key] === 'boolean' ||
          (typeof this.processedFilterForm[key] === 'string' &&
            this.processedFilterForm[key])
        ) {
          const field = {
            column: key,
            value: this.processedFilterForm[key],
            operation: 'Equal',
          };

          processedFilter.push(field);
        } else if (Array.isArray(this.processedFilterForm[key])) {
          const values = [];
          this.processedFilterForm[key].forEach((item) => {
            values.push(item);
          });

          const field = {
            column: key,
            operation: 'Equal',
            values,
          };

          processedFilter.push(field);
        }
      }

      this.filterForRequest = processedFilter;
    },

    setFilterToQuery() {
      this.compressionFilterForQuery();

      const currentQuery = this.$route.query;
      const newQuery = {
        table: JSON.stringify(this.table),
        filter: JSON.stringify(this.filterForQuery),
      };

      const isEqual = this.compareTwoValues(currentQuery, newQuery);
      if (!isEqual) {
        this.$router.replace({
          query: newQuery,
        });
      }
    },

    compareTwoValues(value1, value2) {
      return this.lodash.isEqual(value1, value2);
    },

    setBadgesFilterCatalogsById(list) {
      list.forEach((listParameter) => {
        if (listParameter.filterType === 'FromCatalog') {
          const currentLargeCatalog = this.currentFilterField.filter((item) => {
            return item.catalogIsLarge && item.name === listParameter.name;
          });
          if (currentLargeCatalog && currentLargeCatalog.length) {
            currentLargeCatalog.forEach((largeCatalog) => {
              if (!this.filterForm[largeCatalog.name]) return;
              const payload = {
                name: listParameter.catalogName,
                ids: Array.isArray(this.filterForm[largeCatalog.name])
                  ? this.filterForm[largeCatalog.name]
                  : [this.filterForm[largeCatalog.name]],
              };
              this.getFilterItemsById({
                catalogName: listParameter.catalogName,
                payload,
              }).then((res) => {
                const value = res.items.map((item) => item.name);

                if (this.badges.length) {
                  this.badges.map((badge) => {
                    if (badge.key === largeCatalog.name) {
                      badge.value = value;
                    }
                  });
                }

                const badge = {
                  key: largeCatalog.name,
                  name: largeCatalog?.label,
                  operation: this.filterTypesValues
                    .find((item) => item.value === 'Equal')
                    .name.toLowerCase(),
                  value,
                };

                if (
                  !this.badges.some((badge) => badge.key === largeCatalog.name)
                ) {
                  this.badges.push(badge);
                }

                res.items.forEach((item) => {
                  const currentOption = largeCatalog.values.find(
                    (value) => item.id === value.id
                  );
                  if (!currentOption) {
                    largeCatalog.values.push(item);
                  }
                });
              });
            });

            this.filterBadges = this.badges;
          }
        }
      });
    },

    setInitialFilterBadges() {
      this.setBadgesFilterCatalogsById(this.processedListParameterColumns);
    },

    setfilterBadges() {
      for (const key in this.filterForm) {
        // Если в форме значение сброшено, то сбросить также и в badges
        const currentBadge = this.badges.find((badge) => badge.key === key);
        if (!this.filterForm[key] && currentBadge) {
          this.badges = this.badges.filter((badge) => badge.key !== key);
        }
        if (
          this.filterForm[key] &&
          typeof this.filterForm[key] === 'object' &&
          this.filterForm[key].operation
        ) {
          const badge = {
            value:
              this.filterForm[key].operation === 'Between'
                ? `${this.filterForm[key].valueFrom} - ${this.filterForm[key].valueTo}`
                : this.filterForm[key].value,
            key,
            name: this.currentFilterField.find((item) => item.name === key)
              ?.label,
            operation: this.filterTypesValues
              .find((item) => item.value === this.filterForm[key].operation)
              .name.toLowerCase(),
          };

          if (!this.badges.some((badge) => badge.key === key)) {
            this.badges.push(badge);
          }
          this.badges.map((item) => {
            if (item.key === key) {
              item.value = badge.value;
              item.operation = badge.operation;
              return;
            }
            return item;
          });
        } else if (
          typeof this.filterForm[key] === 'object' &&
          this.filterForm[key].operation === ''
        ) {
          this.badges = this.badges.filter((badge) => badge.key !== key);
        } else if (typeof this.filterForm[key] === 'boolean') {
          const badge = {
            key,
            name: this.currentFilterField.find((item) => item.name === key)
              ?.label,
            operation: this.filterTypesValues
              .find((item) => item.value === 'Equal')
              .name.toLowerCase(),
            value: this.filterForm[key] ? 'Да' : 'Нет',
          };

          if (!this.badges.some((badge) => badge.key === key)) {
            this.badges.push(badge);
          }

          this.badges.map((item) => {
            if (item.key === key) {
              return (item.value = badge.value);
            }
            return item;
          });
        } else if (
          typeof this.filterForm[key] === 'string' &&
          this.filterForm[key]
        ) {
          const currentFieldCatalog = this.currentFilterField.find(
            (item) => item.name === key
          );

          if (
            currentFieldCatalog &&
            currentFieldCatalog.filterType === 'FromCatalog'
          ) {
            if (!currentFieldCatalog.values) return;

            const currentFilterCatalogSelected =
              currentFieldCatalog.values.filter(
                (item) => item.id === this.filterForm[key]
              );

            const badge = {
              key,
              name: this.currentFilterField.find((item) => item.name === key)
                ?.label,
              operation: this.filterTypesValues
                .find((item) => item.value === 'Equal')
                .name.toLowerCase(),
              value: Array.from(
                currentFilterCatalogSelected.map((item) => item.name)
              ),
            };

            if (!this.badges.some((badge) => badge.key === key)) {
              this.badges.push(badge);
            }

            this.badges.map((item) => {
              if (item.key === key) {
                return (item.value = badge.value);
              }
              return item;
            });
          } else if (currentFieldCatalog.filterType === 'FromList') {
            const currentFilterCatalogSelected =
              currentFieldCatalog.values.filter(
                (item) => item.id === this.filterForm[key]
              );

            const badge = {
              key,
              name: this.currentFilterField.find((item) => item.name === key)
                ?.label,
              operation: this.filterTypesValues
                .find((item) => item.value === 'Equal')
                .name.toLowerCase(),
              value: Array.from(
                currentFilterCatalogSelected.map((item) => item.name)
              ),
            };

            if (!this.badges.some((badge) => badge.key === key)) {
              this.badges.push(badge);
            }

            this.badges.map((item) => {
              if (item.key === key) {
                return (item.value = badge.value);
              }
              return item;
            });
          }
        } else if (Array.isArray(this.filterForm[key])) {
          const currentFieldCatalog = this.currentFilterField.find(
            (item) => item.name === key
          );
          const currentFilterCatalogOptions =
            this.$store.state.filter[currentFieldCatalog.catalogName]?.data
              ?.items;

          if (
            currentFilterCatalogOptions &&
            currentFilterCatalogOptions.length
          ) {
            const currentFilterCatalogSelected = [];

            this.filterForm[key].forEach((item) => {
              if (
                !currentFilterCatalogSelected.some(
                  (selectedOption) => selectedOption.id === item
                )
              ) {
                const currentOption = currentFilterCatalogOptions.find(
                  (option) => option.id === item
                );
                if (!currentOption) return;
                currentFilterCatalogSelected.push(currentOption);
              }
            });

            this.badges.map((badge) => {
              if (badge.key === key) {
                badge.value = this.filterForm[key].map((item) => {
                  {
                    const currentOption = currentFilterCatalogOptions.find(
                      (option) => option.id === item
                    );
                    if (!currentOption) return;

                    return currentOption.name;
                  }
                });
              }
            });

            const badge = {
              key,
              name: this.currentFilterField.find((item) => item.name === key)
                ?.label,
              operation: this.filterTypesValues
                .find((item) => item.value === 'Equal')
                .name.toLowerCase(),
              value: Array.from(
                currentFilterCatalogSelected.map((item) => item.name)
              ),
            };

            if (!this.badges.some((badge) => badge.key === key)) {
              this.badges.push(badge);
            }
          }
        }
      }

      this.filterBadges = this.badges;
    },

    onRemoveBadge(key) {
      if (this.filterForm[key].options === 'Between') {
        this.filterForm[key].valueFrom = '';
        this.filterForm[key].valueTo = '';
        this.filterForm[key].operation = '';
      } else if (
        Array.isArray(this.filterForm[key]) ||
        typeof this.filterForm[key] === 'boolean' ||
        (typeof this.filterForm[key] === 'string' && this.filterForm[key])
      ) {
        this.filterForm[key] = '';
      } else if (this.filterForm[key].value && this.filterForm[key].operation) {
        this.filterForm[key].value = '';
        this.filterForm[key].operation = '';
      }

      // Очистка зависимых (дочерних) списков
      this.currentFilterField.forEach((item) => {
        if (item.catalogParent !== key) return;
        this.filterForm[item.name] = '';
      });

      this.$nextTick(() => this.onSearch());
    },

    onSearch() {
      this.compressionFilterForQuery();
      this.compressionFilterForRequest();
      this.table.skip = 0;
      this.setfilterBadges();
      this.setBadgesFilterCatalogsById(this.processedListParameterColumns);
      this.setFilterToQuery();
      this.fetchList();
    },

    onReset() {
      this.filterForm = this.lodash.cloneDeep(this.initialFilterForm);
      this.filterForRequest = [];
      this.filterForQuery = {};
      this.filterBadges = [];
      this.table.skip = 0;
      this.setFilterToQuery();

      this.fetchList();
    },

    onFetchItems(requestData) {
      const field = requestData.field;
      if (field.catalogDependencies && field.catalogDependencies.length) {
        const dependencyFilters = field.catalogDependencies.map((item) => {
          const values = Array.isArray(this.filterForm[item.columnName])
            ? this.filterForm[item.columnName].map((item) => Number(item))
            : [Number(this.filterForm[item.columnName])];

          return {
            name: item.dependencyName,
            values: this.filterForm[item.columnName] ? values : [],
          };
        });

        this.getFilterCatalogsItems({
          name: field.catalogName,
          ...requestData.params,
          parentId: field.catalogParent
            ? this.filterForm[field.catalogParent]
            : null,
          dependencyFilters,
        });
      } else {
        this.getFilterCatalogs({
          catalogName: field.catalogName,
          params: {
            ...requestData.params,
          },
        });
      }
    },

    fetchFilterCatalogs(list) {
      list.forEach((listParameter) => {
        if (
          listParameter.filterType === 'FromCatalog' &&
          !listParameter.catalogParent
        ) {
          this.getFilterCatalogs({
            catalogName: listParameter.catalogName,
            params: {
              ...(listParameter.catalogIsLarge ? { limit: 10 } : {}),
            },
          });
        }
      });
    },

    // Метод срабатывает при переключении множественного выбора
    onUpdateFilter(field) {
      if (this.filterForm[field.name]) {
        // Очистить соответсвующее значение в форме фильтра
        this.filterForm[field.name] = '';

        // Также очистить значения зависимых списков
        this.clearChildList(field);
      }
    },

    // Метод для очистки зависимых списков
    clearChildList(field) {
      this.currentFilterField.forEach((item) => {
        if (item.catalogParent !== field.name) return;
        this.filterForm[item.name] = '';
      });
    },
    // [END] Methods for filter

    // [START] Methods for list
    fetchList() {
      if (this.currentParentName && this.parentId) {
        return this.getChildList({
          name: this.controllerName,
          parentName: this.currentParentName,
          parentId: this.parentId,
          params: { ...this.table, filters: this.filterForRequest },
        });
      }
      return this.getList({
        name: this.controllerName,
        params: { ...this.table, filters: this.filterForRequest },
      });
    },

    // fetchChildList() {
    //   return this.getChildList({
    //     controllerName: this.controllerName,
    //     parentName: this.currentParentName,
    //     parentId: this.parentId,
    //     params: this.table,
    //   });
    // },

    onSortBy(col) {
      if (col.order !== this.table.order) {
        this.table.orderDesc = true;
        this.table.order = col.order;
      } else {
        this.table.orderDesc = !this.table.orderDesc;
      }
    },

    onCheckItems(items) {
      this.checkedItemIds = items;
      this.$emit('check-items', items);
    },

    onItemsPerPage(count) {
      this.table.skip = 0;
      this.table.count = count;
    },

    onChangePage(page) {
      if (this.showNextpageQuestion) {
        this.$root
          .$confirmModal({
            message:
              'При переходе на следующую страницу все выбранные данные будут сброшены',
            leftButtonText: 'Сбросить',
            rightButtonText: 'Отменить',
          })
          .then((response) => {
            if (response) {
              this.$emit('change-page', page);
              this.table.skip = (page - 1) * this.table.count;
            }
          });
      } else {
        this.$emit('change-page', page);
        this.table.skip = (page - 1) * this.table.count;
      }
    },

    onCreate() {
      this.$emit('create');
    },

    onEdit(id) {
      this.$emit('edit', id);
    },

    openDeleteModal(id) {
      this.idsToDelete = [id];
      this.$root
        .$confirmModal({
          message: 'Удалить запись?',
          leftButtonText: 'Удалить',
          rightButtonText: 'Отменить',
        })
        .then((resposne) => {
          if (!resposne) {
            this.idsToDelete = [];
            return;
          }

          this.removeItem({
            name: this.controllerName,
            ids: this.idsToDelete,
          }).then(() => {
            this.idsToDelete = [];
            this.fetchList();
          });
        });
    },

    openMultideleteModal() {
      // нужно ли мультиудаление для зависимых списков?
      const ids = this.checkedItemIds.map((item) => item.id);

      this.$root
        .$confirmModal({
          message: 'Удалить выбранные записи?',
          leftButtonText: 'Удалить',
          rightButtonText: 'Отменить',
        })
        .then((resposne) => {
          if (!resposne) {
            this.idsToDelete = [];
            return;
          }

          this.removeItem({
            controllerName: this.controllerName,
            ids,
          }).then(() => {
            this.checkedItemIds = [];
            this.fetchList();
          });
        });
    },

    // [END] Methods for list

    // [START] Methods for available columns
    setInitialAvailableHeadersForm() {
      // модель для колонки(поля) таблицы
      const model = {
        checked: true,
        disabled: false,
      };
      let storageColumns = getItemFromStorage('availableTablesColumns');
      let preparedColumns = this.tableHeaders.reduce(
        (previousItem, currentItem) => {
          return {
            ...previousItem,
            [currentItem.alias]: this.lodash.cloneDeep(model),
          };
        },
        {}
      );
      if (storageColumns && storageColumns[this.controllerName]) {
        // Приводим модель каждой элемента к новому формату
        Object.entries(storageColumns[this.controllerName]).forEach((entry) => {
          if (
            // Если ключи в модели не совпадают с localStorage значением
            !this.lodash.isEqual(
              Object.keys(model).sort(),
              Object.keys(entry[1]).sort()
            )
          ) {
            // Получаем ключи, которых нет в модели
            const differenceKeys = this.lodash.difference(
              this.lodash.keys(entry[1]),
              this.lodash.keys(model)
            );
            // Перезаписываем поле в localStorage
            // 1. Сначала сохраняем модель
            // 2. Затем с помощью omit удаляем ключи(differenceKeys массив) из localStorage поля
            storageColumns[this.controllerName][entry[0]] = {
              ...this.lodash.cloneDeep(model),
              ...this.lodash.omit(entry[1], differenceKeys),
            };
            // Таким образом, в localStorage не будут попадать столбцы, которых больше нет в модели.
          }
        });
        // Находим пересекающиеся поля в хранилище и пришедшей таблице
        const intersectionFields = this.lodash.intersection(
          this.lodash.keys(storageColumns[this.controllerName]),
          this.lodash.keys(preparedColumns)
        );
        // По найденным полям переносим состояние столбцов из хранилища в новую таблицу
        intersectionFields.forEach((key) => {
          preparedColumns[key] = storageColumns[this.controllerName][key];
        });
        // если допустим, в хранилище есть нужная таблица со столбцами [a,b,c], но в таблице поменяли колонки на [b,c,d]
        // то в скрипте выше intersectionFields покажет перечении двух массивов [b,c]
        // и по нему перенесет в preparedColumns [b: false, c: true, d: true]
        // Так как колонки A нет в новой таблице, то она не попадет в новую версию таблицы
        // И поле D в новой версии таблицы по умолчанию будет включено для пользователя
        // Таким образом при изменении this.availableHeadersForm сработает watch'ер
        // который сохранит только актуальные столбцы
      }
      this.availableHeadersForm = preparedColumns;
    },
    // [END] Methods for available columns

    onClickLongProcessLink(route) {
      const params = {
        previosRouteName: this.$route.name,
      };

      if (this.$route.query?.filter) {
        params.previosRouteQueryFilter = JSON.parse(this.$route.query.filter);
      }

      if (this.$route.query?.table) {
        params.previosRouteQuerySort = JSON.parse(this.$route.query.table);
      }

      this.$router.push({
        name: route.name,
        params,
      });
    },
  },
};
</script>

<style lang="scss">
.editable-list__breadcrumbs {
  margin-bottom: 15px;
}

.editable-list__top-panel-button {
  display: flex;
  align-items: center;

  font-weight: 500;
  line-height: 1;
  color: var(--gray);

  &:hover {
    color: var(--blue);
    cursor: pointer;
  }
}

.editable-list__top-panel-button--delete {
  &:hover {
    color: var(--red);
  }
}

.editable-list__datatable-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  text-align: center;
}

.editable-list__datatable-button + .editable-list__datatable-button {
  margin-right: 5px;
}

.editable-list__top-panel-button--create,
.editable-list__datatable-button {
  &:hover {
    color: var(--blue);
  }
}

.editable-list__datatable-button {
  font-size: 0;
  color: var(--gray);

  &:last-of-type {
    margin-right: 0;
  }

  &--edit {
    height: 36px;
    width: 36px;
    border-radius: 5px;
    color: var(--white);
    background-color: var(--green);
    transition: background-color 0.2s;

    &:hover {
      color: var(--white);
      background-color: var(--dark-blue);
    }
  }

  &--view {
    height: 36px;
    width: 36px;
    border-radius: 5px;
    color: var(--white);
    background-color: var(--blue);
    transition: background-color 0.2s;

    &:hover {
      color: var(--white);
      background-color: var(--dark-blue);
    }
  }

  &--delete {
    height: 36px;
    width: 36px;
    border-radius: 5px;
    color: var(--white);
    background-color: var(--red);
    transition: background-color 0.2s;

    &:hover {
      color: var(--white);
      background-color: var(--dark-blue);
    }
  }
}

.editable-list__link {
  text-decoration: underline;
  color: var(--blue);

  cursor: pointer;
}
</style>
