<template>
  <section class="available-columns">
    <div v-if="showFields" class="available-columns__wrapper">
      <sm-checkbox
        v-for="(column, index) in processedFields"
        :key="index"
        :label="column.text"
        class="available-columns__field"
        :disabled="values[column.alias].disabled"
        v-model="values[column.alias].checked"
        @click.native="onClick(column.alias)"
      />
    </div>
    <button class="available-columns__button" @click="showFields = !showFields">
      Показать/скрыть поля
    </button>
  </section>
</template>

<script>
// component
import SmCheckbox from '@/components/common/forms/SmCheckbox.vue';

export default {
  name: 'SmAvailableColumns',

  components: {
    SmCheckbox,
  },

  model: {
    prop: 'values',
  },

  props: {
    values: {
      type: Object,
      default: () => ({}),
    },

    fields: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      showFields: false,
    };
  },
  computed: {
    processedFields() {
      return this.fields.filter(field => {
        return !!field.text;
      });
    },
  },
  methods: {
    onClick(key) {
      if (this.values[key].disabled) {
        this.$notify({
          header: 'Последний столбец нельзя скрыть в таблице!',
          type: 'error',
          timer: 5000,
        });
      }
    },
  },
};
</script>

<style lang="scss">
.available-columns {
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
  align-self: center;
  margin-left: auto;
}
.available-columns__wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.available-columns__button {
  display: flex;
  align-items: center;

  font-weight: 500;
  line-height: 1;
  color: var(--gray);

  margin: 0;
  border: none;
  background: transparent;

  &:hover {
    color: var(--blue);
  }
}
.available-columns__field {
  padding: 10px;
}
</style>
